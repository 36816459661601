import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Box, Hidden } from "@material-ui/core";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import BoxTypo from "../components/BoxTypo";

import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";
let items = [];

const NewsArea = () => {
  const data = useStaticQuery(graphql`
    query {
      anj: file(relativePath: { eq: "anjusImgProfile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMicrocmsNews {
        edges {
          node {
            newsId
            title
            date
            content
            image {
              url
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Box style={{ backgroundColor: "#F7F6F4" }} margin="auto">
        <Box width="90%" margin="auto">
          <BoxTypo fs="50px">"News</BoxTypo>
          <BoxTypo mb={8} fs="15px" ls="15px" fw="650" pl={3}>
            最新情報
          </BoxTypo>

          <Box margin="auto" width="95%">
            <Grid container style={{ width: "95%", margin: "auto" }}>
              {data.allMicrocmsNews.edges.map(({ node }) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      maxWidth={["100px", "300px", "700px"]}
                      width={["20%", "30%", "50%"]}
                      margin="auto"
                    >
                      <Box key={node.newsId} margin="auto" mb={5} px={1}>
                        {/* <BoxTypo>ここ</BoxTypo> */}
                        <Box textAlign="center">
                          <img src={node.image.url} style={{ width: "100%" }} />
                        </Box>
                        <Box width="100%" margin="auto">
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={12} md={6} margin="auto">
                              <BoxTypo
                                fs={["12px", "16px", "15px"]}
                                fw="650"
                                margin="auto"
                                textAlign="flex-start"
                              >
                                {node.date}
                              </BoxTypo>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} margin="auto">
                              <BoxTypo
                                fs={["11px", "16px", "15px"]}
                                fw="650"
                                margin="auto"
                                textAlign={[
                                  "flex-start",
                                  "flex-start",
                                  "right",
                                ]}
                              >
                                {node.title}
                              </BoxTypo>
                            </Grid>
                          </Grid>
                        </Box>

                        <BoxTypo
                          width="100%"
                          fs={["10px", "16px", "15px"]}
                          margin="auto"
                          justifyContent="space-between"
                        >
                          {node.content}
                        </BoxTypo>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewsArea;
