import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      anj: file(relativePath: { eq: "anjusImgProfile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box></Box>
    </>
  );
};

export default Profile;
