import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "./WrapTypo"
import Grid from "@material-ui/core/Grid"
import { Hidden } from "@material-ui/core"

const Pastevent = () => {
  const data = useStaticQuery(graphql`
    query {
      sp_logos: file(relativePath: { eq: "sp_logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc_logos: file(relativePath: { eq: "pc_logos.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Box width="100%" mt="200px" textAlign="center">
        <WrapTypo fs="36px" color="rgba(128, 161, 221, 1)">
          PAST MEDIA
        </WrapTypo>
        <Box
          maxWidth="1200px"
          width="90%"
          bgcolor="#fff"
          m={"auto"}
          mt="32px"
          py={[10, 10, 15, 15]}
          textAlign="center"
        >
          <Hidden xsDown style={{ textAlign: "center" }}>
            <Img
              fluid={data.pc_logos.childImageSharp.fluid}
              style={{ width: "60%", margin: "auto" }}
            />
          </Hidden>
          <Hidden mdUp>
            <Img
              fluid={data.sp_logos.childImageSharp.fluid}
              style={{ width: "80%", margin: "auto" }}
            />
          </Hidden>
        </Box>
      </Box>
    </>
  )
}

export default Pastevent
