import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";

const RecentNews = () => {
  const news = [
    {
      date: "2020.11.18",
      media: "テレビ朝日",
      text:
        "大学生とつくる就活応援ニュースゼミ教えて先輩！“シェア伝道師” GSテンプレートさん「使命感」を持つこと",
      link: "https://abcdefgh.com"
    },
    {
      date: "2020.11.18",
      media: "テレビ朝日",
      text:
        "大学生とつくる就活応援ニュースゼミ教えて先輩！“シェア伝道師” GSテンプレートさん「使命感」を持つこと",
      link: "https://abcdefgh.com"
    },
    {
      date: "2020.11.18",
      media: "テレビ朝日",
      text:
        "大学生とつくる就活応援ニュースゼミ教えて先輩！“シェア伝道師” GSテンプレートさん「使命感」を持つこと",
      link: "https://abcdefgh.com"
    }
  ];
  return (
    <>
      <Box width="270px">
        <WrapTypo variant="h6">RECENT NEWS</WrapTypo>

        {news.map((c, i) => {
          return (
            <WrapTypo
              fs="14px"
              fw="500"
              color="rgba(71, 71, 71, 1)"
              mt="24px"
              Notosans
            >
              {c.date}|{c.media}
              <br />
              {c.text}　
              <Link
                to={c.link}
                style={{
                  fontFamily: "CooperMd",
                  color: "rgba(128, 183, 245, 1)",
                  borderBottom: "solid 1px rgba(128, 183, 245, 1)"
                }}
              >
                LINK
              </Link>
            </WrapTypo>
          );
        })}
      </Box>
    </>
  );
};

export default RecentNews;
