import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";
import { Button } from "@material-ui/core";

const Profile = props => {
  const { isTop = true } = props;
  const data = useStaticQuery(graphql`
    query {
      media1: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media2: file(relativePath: { eq: "media2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media3: file(relativePath: { eq: "media3.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media4: file(relativePath: { eq: "media4.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media5: file(relativePath: { eq: "media5.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media6: file(relativePath: { eq: "media6.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media7: file(relativePath: { eq: "media7.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media8: file(relativePath: { eq: "media8.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media9: file(relativePath: { eq: "media9.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const content = [
    {
      img: data.media7,
      url: "https://wired.jp/2020/12/30/share-summit-2020/",
      date: "2021.04 ~ ",
      media: "テレビ朝日「羽鳥慎一モーニングショー」",
      title: "木曜レギュラーコメンテーターとして出演中"
    },
    {
      img: data.media1,
      url: "https://wired.jp/2020/12/30/share-summit-2020/",
      date: "2020.12.30",
      media: "WIRED掲載",
      title:
        "ウェルビーイングの次なるフェーズへ：「SHARE SUMMIT 2020」で語られたこれからの豊かさについて"
    },
    {
      img: data.media2,
      url: "https://share.jp/interview/share2021/",
      date: "2020.12.30",
      media: "Share.jp掲載",
      title:
        "GSテンプレートさんに聞く！2021年のシェアリングエコノミーのトレンドとは？"
    },
    {
      img: data.media3,
      url: "https://conf-x2020.peatix.com/?lang=ja",
      date: "2020.12.11",
      media: "Conference X",
      title: "コロナ禍を生き抜く「クロス」戦略と最新事例"
    },
    {
      img: data.media4,
      url: "https://genic-web.com/articles/3336992",
      date: "2020.12.7",
      media: "GENIC 1月号",
      title: "デュアルライフ&移住ライフ 掲載"
    },
    {
      img: data.media5,
      url: "https://www3.nhk.or.jp/news/special/news_seminar/senpai/senpai62/",
      date: "2020.11.27",
      media: "NHK",
      title:
        "大学生とつくる就活応援ニュースゼミ「教えて先輩！」“シェア伝道師”「使命感」を持つこと"
    },
    {
      img: data.media6,
      url: "https://listentoheronline.peatix.com/view",
      date: "2020.11.21",
      media: "Listen to Her",
      title: "今こそ聞きたいビジョナリー・ウーマンの話"
    },
    {
      img: data.media7,
      url: "https://www.tv-asahi.co.jp/m-show/",
      date: "2020.11.18",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演"
    },
    {
      img: data.media8,
      url: "https://newspicks.com/movie-series/28?movieId=967",
      date: "2020.11.17",
      media: "WEEKLY OCHIAI",
      title: "家族とは何か？「家族のかたち」を考える"
    },
    {
      img: data.media9,
      url: "https://publications.asahi.com/ecs/12.shtml",
      date: "2020.10.26",
      media: "AERA",
      title: "「21世紀を作るニッポン人名鑑」"
    }
  ];

  const override = isTop ? content.slice(0, 3) : content;
  return (
    <>
      <Box
        width="90%"
        m={"auto"}
        mt={isTop ? 20 : 5}
        maxWidth="1200px"
        id="media"
        mb={18}
      >
        <WrapTypo
          fs="36px"
          color="rgba(128, 161, 221, 1)"
          mt="32px"
          mb="32px"
          ml="20px"
        >
          MEDIA
        </WrapTypo>
        <Grid container justify="center">
          {override.map(c => {
            return (
              <Grid item xs={12} md={4}>
                <Box width="90%" my={3} margin="auto">
                  <Link to={c.url} target="_blank" rel="noopener noreferrer">
                    <Img
                      style={{ width: "100%", margin: "auto" }}
                      fluid={c.img.childImageSharp.fluid}
                    />
                    <WrapTypo
                      Notosans
                      mt="8px"
                      mb="32px"
                      fs="14px"
                      color="#717171"
                    >
                      {c.date}|{c.media}
                      <br />
                      {c.title}
                    </WrapTypo>
                  </Link>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {isTop ? (
          <Box m={"auto"} width="50%" textAlign="center">
            <Link to="/media">
              <Button
                style={{
                  border: "solid 1px rgba(128, 161, 221, 1)",
                  backgroundColor: "rgba(128, 161, 221, 1)"
                }}
              >
                <WrapTypo
                  Notosans
                  fs="14px"
                  mt="8px"
                  mb="8px"
                  ml="24px"
                  mr="24px"
                  color="#fff"
                >
                  全て見る
                </WrapTypo>
              </Button>
            </Link>
          </Box>
        ) : (
          <Box m={"auto"} width="50%" textAlign="center">
            <Link to="/">
              <Button
                style={{
                  border: "solid 1px rgba(128, 161, 221, 1)",
                  backgroundColor: "rgba(128, 161, 221, 1)"
                }}
              >
                <WrapTypo
                  Notosans
                  fs="14px"
                  mt="8px"
                  mb="8px"
                  ml="24px"
                  mr="24px"
                  color="#fff"
                >
                  TOPへ戻る
                </WrapTypo>
              </Button>
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Profile;
