import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      content1: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content2: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content3: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      content4: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      note_ogp: file(relativePath: { eq: "note_ogp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const blogContent = [
    {
      link: "",
      img: "",
      title: "",
      text: ""
    }
  ];
  const news = [
    {
      date: "2021.3.6",
      media: "テスト",
      text: "テスト",
      link: "/"
    }
  ];
  return (
    <>
      <Box width="90%" maxWidth="1200px" m={"auto"} mt="200px">
        <Grid container justify="space-between">
          <Grid xs={12} sm={6}>
            <WrapTypo
              fs="36px"
              color="rgba(128, 161, 221, 1)"
              ml="1.5%"
              mt="80px"
            >
              NEWS
            </WrapTypo>
            <Box
              width="97%"
              bgcolor="#fff"
              m={"auto"}
              mt="24px"
              height="680px"
              overflow="scroll"
            >
              <Box width="90%" ml="5%" mb={8}>
                {news.map((c, i) => {
                  return (
                    <WrapTypo
                      fs="14px"
                      color="rgba(71, 71, 71, 1)"
                      mt="32px"
                      Notosans
                    >
                      {c.date}
                      <br />
                      {c.media.length ? (
                        <>
                          {c.media}
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {c.text.length ? <>{c.text}</> : <></>}
                      {c.link.length ? (
                        <>
                          <Link
                            to={c.link}
                            style={{
                              fontFamily: "CooperMd",
                              color: "rgba(128, 183, 245, 1)",
                              borderBottom: "solid 1px rgba(128, 183, 245, 1)"
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LINK
                          </Link>
                        </>
                      ) : (
                        <></>
                      )}
                    </WrapTypo>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} justify="center" id="note">
            <WrapTypo
              fs="36px"
              color="rgba(128, 161, 221, 1)"
              ml="1.5%"
              mt="80px"
            >
              NOTE
            </WrapTypo>
            <Box
              width="97%"
              bgcolor="#fff"
              m={"auto"}
              mt="24px"
              height="680px"
              overflow="scroll"
            >
              <Grid container>
                {blogContent.map((c, i) => {
                  return (
                    <Grid xs={12} sm={6} className={"media-" + i}>
                      <Link to={c.link}>
                        <Box
                          width="80%"
                          m={"auto"}
                          my="16px"
                          textAlign="justify"
                        >
                          {c.img == "note_ogp" ? (
                            <>
                              <Img
                                style={{ width: "100%", borderRadius: "6px" }}
                                fluid={data.note_ogp.childImageSharp.fluid}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={c.img}
                                style={{ width: "100%", borderRadius: "6px" }}
                              />
                            </>
                          )}

                          <WrapTypo Notosans fw="700" mt="8px">
                            {c.title}
                          </WrapTypo>
                          <WrapTypo color="#717171" Notosans fs="12px" mt="8px">
                            {c.text.substr(0, 50)}...
                          </WrapTypo>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
